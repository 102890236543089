<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, reactive } from "vue";
import { useBouncer } from "@/compositions/useBouncer";

export default {
  name: "ManagementContainer",
  setup() {
    const bouncer = useBouncer();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      employees: [],
      team: {}
    });

    store.dispatch("team/getTeam", route.params.id).then(r => {
      state.team = r.data;
      bouncer.init(r.data.abilities, r.data.abilities_forbidden);
    });

    return {
      team: computed(() => state.team),
      route,
      bouncer
    };
  }
};
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Profile Menu -->
    <div
      class="col-span-12 lg:col-span-3 xxl:col-span-3 flex lg:block flex-col-reverse"
    >
      <div class="intro-y box mt-5">
        <div class="flex items-center p-5 space-x-2">
          <div class="w-14 h-14 relative image-fit">
            <img
              class="rounded-md"
              rel="preload"
              alt="Quant Lines"
              :src="team.logo"
            >
          </div>
          <div class="relative flex flex-col justify-start items-start">
            <span class="block font-medium text-base text-indigo-600">{{
              team.name
            }}</span>
            <a
              class="block mt-3"
              target="_blank"
              href="https://meet.google.com/"
            >
              <img
                src="@/assets/icons/google-meet.svg"
                class="w-24"
              >
            </a>
          </div>
        </div>
        <div class="p-5 border-t border-gray-200 dark:border-dark-5">
          <router-link
            v-if="bouncer.can('team-members-view')"
            :to="{
              name: 'dashboard.team.show',
              params: { id: route.params.id }
            }"
            class="flex items-center font-medium p-2 rounded hover:bg-gray-200"
            :class="{
              'text-theme-1 bg-gray-200':
                $route.name === 'dashboard.team.members'
            }"
          >
            <i class="ri-user-3-fill mr-2" /> Members
          </router-link>
          <router-link
            v-if="bouncer.can('team-invites-view')"
            :to="{
              name: 'dashboard.team.invites',
              params: { id: route.params.id }
            }"
            class="flex items-center font-medium p-2 rounded hover:bg-gray-200"
            :class="{
              'text-theme-1 bg-gray-200':
                $route.name === 'dashboard.team.invites'
            }"
          >
            <i class="ri-user-received-2-fill mr-2" /> Invites
          </router-link>
          <router-link
            v-if="bouncer.can('team-settings-view')"
            :to="{
              name: 'dashboard.team.management.settings',
              params: { id: route.params.id }
            }"
            class="flex items-center font-medium p-2 rounded hover:bg-gray-200"
            :class="{
              'text-theme-1 bg-gray-200':
                $route.name === 'dashboard.team.management.settings'
            }"
          >
            <i class="ri-settings-2-fill mr-2" /> Settings
          </router-link>
        </div>
      </div>
    </div>
    <!-- END: Profile Menu -->
    <div class="col-span-12 lg:col-span-9 xxl:col-span-9">
      <router-view />
    </div>
  </div>
</template>
